import React, { useEffect, createRef } from "react"
import LayoutTeam from "../components/layoutteam"
import Navigation from "../components/navigation"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import GofloaterSearchForm from "../components/search/searchalter"
import "../styles/hybrid.scss"
import SEOHeader from "../components/seo-header"
import teamLogo from "../img/TeamLogo.svg"
import lottie from "lottie-web"
import SafeSpace from "../img/safety-badge.jpg"
import animation from "../animations/newhomeanimation.json"
import "../styles/coworking.scss"

import { Typeahead } from "react-bootstrap-typeahead"
export default function MeetingSpaces() {
  // const cityImage = [
  //   "bengaluru": ["https://cdn.app.gofloaters.com/images%2F1571484315853_8%20Seater%20Meeting%20Room%20in%20MG%20Road%204.JPG?alt=media&token=586512e9-23e1-492e-9ae2-33da4acbd30f"],

  //   {
  //     chennai:
  //       "https://cdn.app.gofloaters.com/images%2F8%20Seater%20Conference%20Room%20%7C%20S1_1651576770092?alt=media&token=59a7ab90-2f06-435c-a60e-23ba31dd4a98",
  //   },
  //   {
  //     mumbai:
  //       "https://cdn.app.gofloaters.com/images%2F4%20Seater%20Meeting%20Room%20%7C%20Andheri%20East_1705491808796?alt=media&token=c249f0cc-b57b-46f9-874f-c1867184aa70",
  //   },
  // ]

  const data = useStaticQuery(graphql`
    query MeetingPageNewQuery {
      allCities {
        totalCount
        edges {
          node {
            city
            cityPriority
            displayCity
            slug
          }
        }
      }
      allListings(
        filter: { spaceType: { eq: "Conference Room" }, slug: { ne: "" } }
        sort: { fields: isFeatured, order: DESC }
      ) {
        totalCount
        edges {
          node {
            monthPassAvailable
            dayPassAvailable
            hourPassAvailable
            officeSpaceType
            purposesList
            spaceAddress
            spaceGFName
            OriginalName
            spaceCity
            spaceId
            spaceImage
            spaceTitle
            spaceDesc
            spaceType
            subType
            priceDay
            priceHr
            priceMonth
            spaceDisplayName
            Facility
            slug
            hasCovidSafeBadge
            online
            Rating
            pincode
            bookingcount
            isFeatured
            priceperdayPreDiscount
            priceperhrPreDiscount
          }
        }
      }
    }
  `)
  const cities = data
  const citiesDisplay = data.allCities.edges.map(city => city.node.city)

  let animationContainer = createRef()
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    return () => {
      animationContainer.current = animationContainer.current || {}
      anim.destroy(animationContainer.current)
    } // optional clean up for unmounting
  }, [animationContainer])

  const CityImage = props => {
    const imageBucket = {
      coworkingPhotos: [],
      meetingRoomPhotos: [
        {
          city: "bengaluru",
          photo:
            "https://cdn.app.gofloaters.com/images%2F1571484315853_8%20Seater%20Meeting%20Room%20in%20MG%20Road%204.JPG?alt=media&token=586512e9-23e1-492e-9ae2-33da4acbd30f",
        },
        {
          city: "chennai",
          photo:
            "https://cdn.app.gofloaters.com/images%2F6%20Seater%20Meeting%20Room%20%7C%20Perungudi_1697464574762?alt=media&token=af621a7f-b1e8-4436-b286-f8367ea9ff6e",
        },
        {
          city: "mumbai",
          photo:
            "https://cdn.app.gofloaters.com/images%2F30%20Seater%20Conference%20Room_1647512311160?alt=media&token=9f871cfe-381a-465c-bbea-9fe364b9f499",
        },
        {
          city: "new-delhi",
          photo:
            "https://cdn.app.gofloaters.com/images%2F01A_1646402249397?alt=media&token=65cc7780-ba68-476e-91ea-c07b8db7698b",
        },
        {
          city: "noida",
          photo:
            "https://cdn.app.gofloaters.com/images%2F12%20Seater%20Conference%20Room%20%7C%20Sector%2062_1669307795364?alt=media&token=c1115ea5-a126-4cb4-be25-9c917d0010f5",
        },
        {
          city: "gurugram",
          photo:
            "https://cdn.app.gofloaters.com/images%2F10%20Seater%20Conference%20Room%20%7C%20Sector%2028_1615276736084?alt=media&token=98d4e2e2-d147-42c6-b34d-27f2eae00c3c",
        },
        {
          city: "hyderabad",
          photo:
            "https://cdn.app.gofloaters.com/images%2F9%20Seater%20Meeting%20Room%20%7C%20Financial%20District_1651908908697?alt=media&token=e866eb0a-a873-44e9-ad7b-d1ed1b191b27",
        },
        {
          city: "pune",
          photo:
            "https://cdn.app.gofloaters.com/images%2F8%20Seater%20Meeting%20Room%20%7C%20Baner_1652521530689?alt=media&token=47448ebf-61ed-44dc-9d55-07e132db4523",
        },
        {
          city: "ahmedabad",
          photo:
            "https://cdn.app.gofloaters.com/images%2F25%20Seater%20Conference%20Room%20%7C%20Navrangpura_1667555248731?alt=media&token=b8bd3357-c51e-451d-bdb7-76ce970dacea",
        },
        {
          city: "kochi",
          photo:
            "https://cdn.app.gofloaters.com/images%2F10%20Seater%20Meeting%20Room%20%7C%20Edapally_1650273013150?alt=media&token=79014f38-f8b2-49b3-9f36-26a9f16d930b",
        },
        {
          city: "visakhapatnam",
          photo:
            "https://cdn.app.gofloaters.com/images%2F10%20Seater%20Conference%20Room%20%7C%20Maharanipeta_1675256209211?alt=media&token=ac42ba71-2c82-4d89-9b52-8e37f45ede34",
        },
        {
          city: "thiruvananthapuram",
          photo:
            "https://cdn.app.gofloaters.com/images%2F20%20Seater%20Conference%20Room%20%7C%20Kazhakkoottam_1617187974282?alt=media&token=85caaf94-8924-429c-86af-9ca6146b941b",
        },
        {
          city: "surat",
          photo:
            "https://cdn.app.gofloaters.com/images%2F15%20Seater%20Conference%20Room%20%7C%20Althan_1679732021091?alt=media&token=ff181fe1-06e8-469b-902f-3dfb18ba7784",
        },
        {
          city: "jaipur",
          photo:
            "https://cdn.app.gofloaters.com/images%2F10%20Seater%20Conference%20Room%20%7C%20Nirman%20Nagar_1691763150586?alt=media&token=415b6f9e-6b63-4d85-b789-64630d02b46f",
        },
      ],
    }
    const getPhotoUrlByCity = cityName => {
      const photoObject = imageBucket.meetingRoomPhotos.find(
        photo => photo.city.toLowerCase() === cityName.toLowerCase()
      )

      return photoObject ? photoObject.photo : null
    }

    // Example usage:

    const photoUrl = getPhotoUrlByCity(
      props.name.toLowerCase().replace(" ", "-")
    )
    const localitynew = cities.allListings.edges.filter(
      list => list.node.spaceCity === props.name
    )

    if (localitynew.length > 0) {
      return (
        <div className="space_img" title={props.name}>
          {photoUrl ? (
            ""
          ) : (
            <img
              src={localitynew[0].node.spaceImage.replace(
                "https://firebasestorage.googleapis.com/v0/b/gofloaters.appspot.com/o",
                "https://cdn.app.gofloaters.com"
              )}
              alt={props.name}
              title={props.name}
            />
          )}
          {photoUrl ? (
            <img src={photoUrl} alt={props.name} title={props.name} />
          ) : (
            ""
          )}
        </div>
      )
    }
    return ""
  }

  const citySelected = city => {
    if (city !== "") {
      const cityNew = cities.allCities.edges.filter(
        list => list.node.city === city[0]
      )

      const citySlug = cityNew[0]?.node.slug
      if (citySlug) {
        navigate("/meeting-spaces/" + citySlug + "/all")
      }
    }
  }
  return (
    <div>
      <SEOHeader
        title="Book best meeting space across India on hourly basis - GoFloaters"
        description="Explore and book top meeting spaces across India instantly for meetings, interviews, training, presentations and discussions. Get access to fully equipped, safe and verified meeting spaces by the hour."
        socialURL="https://assets.gofloaters.com/socialimage/meeting-rooms.jpg"
        pinterest="true"
      ></SEOHeader>

      <LayoutTeam>
        <div className="meetingSpaces">
          <div>
            <Navigation />
            <div className="container">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <h1 className="meetingPageTitleh1">
                    <span style={{ borderBottom: "5px solid #4F9FC8" }}>
                      {cities.allListings.totalCount}+
                    </span>{" "}
                    Conference rooms and meeting spaces in{" "}
                    <span style={{ borderBottom: "5px solid #4F9FC8" }}>
                      {cities.allCities.totalCount}+
                    </span>{" "}
                    cities
                  </h1>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      padding: "10px 0 15px ",
                    }}
                  >
                    Pay-per-use meeting rooms that can be booked by the hour
                    available across India.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-4 text-center">
                  <div className="iconForm">
                    <Typeahead
                      placeholder="Select City..."
                      onChange={selected => {
                        citySelected(selected)
                      }}
                      id="CitySelector"
                      options={citiesDisplay}
                    />
                    <span className="fa fa-caret-down"></span>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <GofloaterSearchForm
                    placeholder="Search by location"
                    spacetype="meetingSpace"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <br></br>
          <div className="row">
            {cities.allCities.edges.map(city => {
              const localitynew = cities.allListings.edges.filter(
                list => list.node.spaceCity === city.node.city
              )
              if (localitynew.length > 0)
                return (
                  <div className="col-md-4 col-6">
                    <Link to={"/meeting-spaces/" + city.node.slug + "/all"}>
                      <CityImage name={city.node.city}></CityImage>
                      <h3
                       className="brandSpaceTitle"
                      >
                        {city.node.city}
                      </h3>
                      <h4
                        className="brandSubTitle"
                      >
                        {localitynew.length} options
                      </h4>
                      <br />
                    </Link>
                  </div>
                )
            })}
          </div>
        </div>
        {/* <div className="padding60">
          <div className="container">
            <div className="row">
              {cities.allCities.edges.map(city => {
                return (
                  <div className="col-md-2 col-4 city-icons">
                    <Link to={"/meeting-spaces/" + city.node.slug + "/"}>
                      <img
                        src={
                          "https://assets.gofloaters.com/coworking/" +
                          city.node.slug +
                          ".svg"
                        }
                        alt={city.node.displayCity}
                      ></img>
                      <br></br>
                      {city.node.displayCity}
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div> */}
        <div className="padding60">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    lineHeight: "1.3",
                  }}
                >
                  Meeting Spaces Amenities
                </h2>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/hi_speed_wifi.png"
                        alt="High Speed WiFi"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>High Speed WiFi</h3>
                      <p>
                        High-Speed WiFi to get you going the moment you enter.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/power_backup.png"
                        alt="Power Backup"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>Power Backup</h3>
                      <p>
                        Power backup to ensure that you are able to work
                        uninterrupted.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/ac.png"
                        alt="AC"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>AC</h3>
                      <p>
                        Climate controlled environment with cooling set
                        appropriately to make your work pleasurable
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/free_parking.png"
                        alt="Parking"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>Parking</h3>
                      <p>Free parking inside and near the space.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/coffee_tea.png"
                        alt="Coffee / Tea"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>Tea & Coffee</h3>
                      <p>
                        Recharge yourself with a coffee or your favorite
                        beverage when needed.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/printer.png"
                        alt="printer"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>Printer</h3>
                      <p>
                        Printing and scanning facilities available on request.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                {" "}
                <br></br>
                <p style={{ fontSize: "12px" }}>
                  * Check the space details page for each space to explore the
                  amenities available at that space
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4 style={{ fontWeight: "bold" }}>
                What kind of meeting spaces can I get on GoFloaters?
              </h4>
              <p>
                You have come to the right space if you are looking for a
                meeting room on rent or a conference room on rent. GoFloaters
                has meeting spaces of all sizes and for all kinds of meetings.
                Whether you are looking to just have a team discussion or
                looking for a room for a presentation or a training session we
                have you covered. You can also get spaces to conduct your
                interviews, onboarding of your new employees or conduct
                workshops at GoFloaters meeting spaces. All of the spaces come
                equipped with the amenities that help you get going the moment
                you enter.
              </p>

              <ul>
                <li>
                  <strong>Flexibility</strong>: No long term contracts that
                  block you
                </li>
                <li>
                  <strong> Cost Effective</strong>: You pay one simple and
                  affordable rent for the space and all the amenities. You can
                  book spaces by the hour. No need to book a space for half a
                  day if you need a space only for 2 hours.{" "}
                </li>
                <li>
                  <strong> Pay-per-use</strong>: This is something unique to
                  GoFloaters where we have made the choicest meeting and
                  conference rooms on rent by the hour.{" "}
                </li>
                <li>
                  <strong> Location choice</strong>: With thousands of options
                  available you can get a meeting room or conference room where
                  you need it{" "}
                </li>
                <li>
                  <strong> Community</strong>: Coworking spaces are the breeding
                  ground for strong communities of entrepreneurs and freelancers{" "}
                </li>
              </ul>
              <br />
              <h4 style={{ fontWeight: "bold" }}>
                Why should I use the GoFloaters app to book?
              </h4>
              <p>
                GoFloaters has been in the space of helping individuals and
                teams find flexible and affordable workspaces for over 6 years
                now. GoFloaters was started with a vision to help anyone get an
                office space when they want, where they want and within their
                budget. We set out to build office spaces for a distributed
                world where individuals and teams should be able to work near
                home.
              </p>
              <br />
            </div>
          </div>
        </div>

        <div className="padding60 brandBackground">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    lineHeight: "1.3",
                    color: "#fff",
                  }}
                >
                  GoFloaters Meeting Spaces Advantage
                </h2>
              </div>
              <div className="container roundWhiteBackground">
                <div className="row">
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/pay-you-go.svg"
                        alt="Pay as you use"
                      ></img>
                      <p>Pay as you use</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/largest-network.svg"
                        alt="Largest Network"
                      ></img>{" "}
                      <p>Largest Network</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/affordable.svg"
                        alt="Affordable"
                      ></img>{" "}
                      <p>Affordable</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/work-near-home.svg"
                        alt="Work near home"
                      ></img>{" "}
                      <p>Work near home</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/no-contract.svg"
                        alt="No contracts"
                      ></img>{" "}
                      <p>No contracts</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/partner-benefits.svg"
                        alt="Partner benefits"
                      ></img>{" "}
                      <p>Partner benefits</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h4 style={{ fontWeight: "bold" }}>
                  How to select the right meeting room for my purpose?
                </h4>
                <p>
                  Selecting an appropriate meeting space is essential to
                  organizing effective and fruitful events. A number of factors
                  are important in this decision-making process, including the
                  size of the room, location, amenities, and booking
                  possibilities. It's critical to take into account both the
                  meeting's objectives and participant count when choosing a
                  space that will suit everyone's needs and facilitate the
                  planned activities. An efficient meeting can be ensured by
                  evaluating the location's convenience, amenities, and
                  technical setup. The appropriateness of the selected meeting
                  location is further improved by transparent pricing and
                  flexible booking options, which promote productive teamwork
                  and communication.
                </p>

                <ol style={{ padding: "0 0 0 40px" }}>
                  <li>
                    <p>
                      <strong>Location</strong>: Choose a convenient location to
                      minimize travel time for attendees. Understand how your
                      attendees will be traveling. Do you need a space near a
                      metro station or do you need a space that is situated on
                      the main road.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Room Size</strong>: Ensure the room can
                      comfortably accommodate all participants. Workshops need
                      larger space for participants to move around.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Facilities</strong>: Assess the availability of
                      technical equipment, such as projectors and screens, as
                      well as amenities like high-speed internet access.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Purpose of the Meeting</strong>: Consider the
                      intended activities and requirements of the meeting. The
                      seating arrangement, ambience and amenities you will need
                      would depend on the purpose. For example a board meeting
                      would need a premium space with ample car parking
                      facilities.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Booking Options</strong>: Look for flexible
                      booking options that suit varying scheduling needs. You
                      can book spaces by the hour or by the day on GoFloaters.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Pricing</strong>: Opt for transparent pricing to
                      avoid hidden costs and ensure affordability. GoFloaters
                      pricing is all inclusive. You do not pay for amenities
                      such as projector or whiteboard separately. All of the
                      GoFloaters spaces provide you with complimentary coffee
                      and tea.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Ratings and Feedback</strong>: Look at the rating
                      and feedback provided by other users for the meeting rooms
                      to understand the experience that you will get in that
                      space.You can sort our space listings based on ratings.
                    </p>
                  </li>
                </ol>
                <br />
                <h4 style={{ fontWeight: "bold" }}>
                  Have a question? We have the answer
                </h4>
                <ol style={{ padding: "0 0 0 40px" }}>
                  <li>
                    <p>
                      <strong>
                        What are the advantages of booking a meeting room via
                        GoFloaters?
                      </strong>
                    </p>
                    <p>
                      Avoid the trouble of getting in touch with certain spaces!
                      You may browse thousands of meeting rooms on our site with
                      GoFloaters. Check features, costs, and locations in one
                      convenient location to select the ideal venue for your
                      gathering. Booking is safe and quick, and you get a
                      confirmation straight away. In addition, our committed
                      support staff is always on hand to address any queries you
                      may have. GoFloaters saves you time and effort by
                      streamlining the meeting space reservation procedure. You
                      get the lowest price guarantee.
                    </p>{" "}
                  </li>

                  <li>
                    <p>
                      <strong>How do I book a meeting room?</strong>
                    </p>
                    <p>
                      Pop in your location or select the city in which you need
                      a meeting room to search for meeting spaces. Use our
                      extensive filters to narrow your search. You can book your
                      meeting room of choice instantly by clicking the Book Now
                      button.
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>
                        How much in advance can I book a meeting room?
                      </strong>
                    </p>

                    <p>
                      You can book a meeting room just 1 hour before your
                      meeting start time even. We would recommend that you book
                      at least 24 hours in advance to ensure that the meeting
                      room is secured for you and you have enough time to plan
                      the logistics.
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>Can I book a meeting room on the weekend?</strong>
                    </p>
                    <p>
                      Many of our meeting rooms are available on Saturdays. Only
                      a few of them are available on Sundays also. It is
                      advisable to book your meeting room for weekends in
                      advance.
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>
                        How do I book a meeting room with a certain amenity?
                      </strong>
                    </p>

                    <p>
                      You can use filters on our website to choose spaces with
                      specific amenities like parking, projector or whiteboard.
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>
                        How can I access the meeting room that I reserved?
                      </strong>
                    </p>
                    <p>
                      You need to carry your booking confirmation in the app or
                      booking confirmation email that you get from GoFloaters.
                      You also need to carry a government issued photo ID proof
                      along. That is all that you need to gain access to the
                      space.
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>How do I modify or cancel a booking?</strong>
                    </p>
                    <p>
                      Till you make the payment, you can cancel the booking.
                      After you have made the payment, you need to contact
                      GoFloaters support team through the chat option to cancel
                      the booking. The support team can also help you with
                      rescheduling.
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>
                        Are there any cancellation charges or penalties?
                      </strong>
                    </p>
                    <p>
                      You can refer to the cancellation FAQs for our
                      cancellation policy.
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>
                        What is your guest policy for meeting room bookings?
                      </strong>
                    </p>
                    <p>
                      You can bring as many guests as the capacity of the
                      meeting room you have booked.
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>
                        Is coffee and tea included in the pricing?
                      </strong>
                    </p>
                    <p>
                      All meeting rooms, discussion rooms and conference rooms
                      that GoFloaters provide complimentary coffee and tea..
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>
                        What's the difference between a discussion room, meeting
                        room and conference room?
                      </strong>
                    </p>
                    <p>
                      Discussion room is meant for 2 to 4 people. These rooms
                      usually are compact in nature and don't come equipped with
                      a whiteboard or projector. Meeting rooms are rooms with
                      smaller capacity ranging from 6 seats to 10 seats. They
                      come equipped with a projector and whiteboard. Conference
                      rooms are typically larger rooms equipped with technology
                      for formal presentations, client meetings, or larger
                      gatherings.
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>How do I connect to the internet?</strong>
                    </p>
                    <p>
                      Different coworking spaces have different mechanisms to
                      provide WiFi access. Some of them have guest WiFi that you
                      can connect to with your mobile number. Some of them
                      specifically send the WiFi credentials to your email id.
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>Where do I make the payment?</strong>
                    </p>
                    <p>
                      Bookings made on GoFloaters are prepaid in nature. You
                      need to make the payment before you access the space. You
                      can pay on the GoFloaters.com website using your credit
                      card, debit card, netbanking, UPI and Wallets.
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>
                        Are there any additional charges to pay for the meeting
                        room at the venue?
                      </strong>
                    </p>
                    <p>
                      No additional charges need to be paid at the venue. In
                      some spaces parking is chargeable.
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>
                        What if I need to extend the duration of my booking?
                      </strong>
                    </p>
                    <p>
                      You can make an additional booking for the duration that
                      you want to extend your booking by. If the same space is
                      available, we would be able to provide the same to you for
                      the extended duration.
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>Do you provide a GST invoice?</strong>
                    </p>
                    <p>
                      Yes, we can provide you with a GST invoice for the
                      payment. Our support team would be happy to help you with
                      this.{" "}
                    </p>
                  </li>
                </ol>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </LayoutTeam>
    </div>
  )
}
